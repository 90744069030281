import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import Select from 'react-select'
import capitalize from 'lodash/capitalize'
import extend from 'lodash/extend'
import isUndefined from 'lodash/isUndefined'
import last from 'lodash/last'
import panzoom from 'panzoom'
import toInteger from 'lodash/toInteger'
import uniq from 'lodash/uniq'
import { Spinner, Button, Modal, ModalBody, ModalFooter, ModalHeader, Badge } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import BoundingBoxCanvas, { boundingBoxDescription, BoundingBoxPrediction, parseBoundingBoxDescription } from './BoundingBoxCanvas'
import CONFIG from '../../config'
import Feedback from './feedback'
import KeyValuesForm from './keyValuesForm'
import Layout from '../../components/layouts/Layout'
import RadioButton from '../../components/common/RadioButton'
import Scale, { DEFAULT_SCALE } from './scale'
import formatGroupLabel from '../../components/consultations/GroupLabel'
import { Conditions_conditions } from '../../hasura/graphQlQueries/types/Conditions'
import { Issue } from '../../lib/issue'
import { QueryName } from '../../hasura/queryNames'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { deletePredictionAction, deletePredictionsAction, insertPredictionsAction } from '../../hasura/slices/predictions'
import { postSlackMessageAction, usersSelector, UsersState } from '../../hasura/slices/users'
import { predictions_normalized_insert_input } from '../../../types/globalTypes'

import {
  checkTrainingIterationCompletedAction,
  fetchMedicalImageAction,
  fetchTrainingIterationAction,
  medicalImagesSelector,
  MedicalImagesState,
  preloadMedicalImagesAction,
} from '../../hasura/slices/medical-images'

import {
  conditionOptions,
  emojiFor,
  filterConditions,
  keyForAwsS3Url,
  preloadImage,
  searchQueryParams,
  speciesFor,
  trackHotjarEvent,
} from '../../lib/helpers'

// @ts-ignore
import nextIcon from '../../lib/images/next-right-dark.png'

enum GradeType {
  DefaultScale = 'default scale',
  Binary = 'binary',
}

export default function BinaryTaggingComponent() {
  const dispatch = useDispatch()

  const elementRef = useRef(null)
  const panzoomRef = useRef(null)

  const [boundingBoxPredictions, setBoundingBoxPredictions] = useState<BoundingBoxPrediction[]>([])
  const [displayFeedbackModal, setDisplayFeedbackModal] = useState(false)
  const [displayTrainingIterationPrompt, setDisplayTrainingIterationPrompt] = useState(false)
  const [flip, setFlip] = useState(false)
  const [gradeType, setGradeType] = useState(GradeType.DefaultScale)
  const [idx, setIdx] = useState<number | undefined>(0)
  const [isDrawingBoundingBox, setIsDrawingBoundingBox] = useState(false)
  const [isInsertingPrediction, setIsInsertingPrediction] = useState(false)
  const [keyDown, setKeyDown] = useState<number | undefined>()
  const [medicalImageIds, setMedicalImageIds] = useState<number[]>([])
  const [otherConditions, setOtherConditions] = useState<Conditions_conditions[]>([])
  const [rotate, setRotate] = useState(0)

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { conditions }: ConsultationsState = useSelector(consultationsSelector)
  const { medicalImage, trainingIteration, isQuerying, binaryTaggingPresignedUrls }: MedicalImagesState = useSelector(
    medicalImagesSelector
  )

  const isDebug = searchQueryParams('debug')?.startsWith('t')
  const isScale = trainingIteration?.type === 'scale' || !trainingIteration?.condition.key_values_json
  const isBoundingBoxes = !isScale && trainingIteration?.condition.key_values_json['is_bounding_box']
  const isBinary = gradeType === GradeType.Binary
  const needsBoundingBoxPrediction = boundingBoxPredictions.some((b) => !b.prediction)

  const prediction = medicalImage?.predictions_normalizeds.find(
    (p) =>
      p.vet_id === user?.id && trainingIteration?.id === p.training_iteration_id && p.condition?.id === trainingIteration.condition.id
  )

  const presignedTaggingImageUrl =
    isQuerying[QueryName.FetchMedicalImage] || !medicalImage?.aws_s3_url
      ? undefined
      : binaryTaggingPresignedUrls.find(
          (b) => keyForAwsS3Url(medicalImage?.aws_s3_url) && b.includes(keyForAwsS3Url(medicalImage.aws_s3_url)!)
        )

  /* 
    Effects
  */

  useEffect(() => {
    const instance = panzoomRef?.current
    if (!presignedTaggingImageUrl || !instance) return

    // @ts-ignore
    instance.moveTo(0, 0)
    // @ts-ignore
    instance.zoomAbs(0, 0, 1)
  }, [presignedTaggingImageUrl])

  useEffect(() => {
    setIsInsertingPrediction(false)
    setRotate(0)
    setFlip(false)
    setBoundingBoxPredictions([])
  }, [medicalImage])

  useLayoutEffect(() => {
    if (isUndefined(idx) || !medicalImageIds.length || !elementRef.current || isBoundingBoxes) return

    // @ts-ignore
    panzoomRef.current = panzoom(elementRef.current!, {
      minZoom: 0.25,
      maxZoom: 4,
      beforeMouseDown: function (e: any) {
        // allow mouse-down panning only if altKey is down. Otherwise - ignore
        var shouldIgnore = e.altKey
        return shouldIgnore
      },
    })

    return () => {
      // @ts-ignore
      panzoomRef.current.dispose()
    }
  }, [idx, medicalImageIds, isBoundingBoxes])

  useEffect(() => {
    const id = parseInt(searchQueryParams('i') || '', 10)
    if (!accessToken || !user || !id) return

    trackHotjarEvent('started_tagging_set')
    dispatch(fetchTrainingIterationAction(accessToken, id))
    return () => {
      dispatch(checkTrainingIterationCompletedAction(accessToken, id, user))
    }
  }, [accessToken, user])

  useEffect(() => {
    const size = 5
    if (idx === undefined || idx % size !== 0 || !binaryTaggingPresignedUrls.length) return

    binaryTaggingPresignedUrls.slice(idx, idx + size).forEach(preloadImage)
  }, [idx, binaryTaggingPresignedUrls])

  useEffect(() => {
    if (!trainingIteration || !user) return

    const medicalImageIds = trainingIteration.medical_image_ids_denormalized.split(',').map(toInteger)
    const idx = Math.max(
      0,
      medicalImageIds.findIndex(
        (id) => !trainingIteration.predictions_normalizeds.some((p) => p.vet_id === user?.id && p.medical_images_id === id)
      )
    )
    if (idx === 0 && trainingIteration.condition.training_iteration_prompt) setDisplayTrainingIterationPrompt(true)
    setIdx(idx)
    setMedicalImageIds(medicalImageIds)
    const yoloLabel = trainingIteration.condition.ml_config?.yolo_label
    dispatch(preloadMedicalImagesAction(accessToken, medicalImageIds, yoloLabel))
    setGradeType(trainingIteration.condition.grade_type as GradeType)
    if (idx === 0) {
      dispatch(
        postSlackMessageAction(
          `${user.display_name} started ${trainingIteration.condition.display_name} ${trainingIteration.species} tagging set (ID: ${trainingIteration.id}).`
        )
      )
    }
  }, [trainingIteration, user])

  useEffect(() => {
    if (!accessToken || !medicalImageIds.length || isUndefined(idx)) return

    dispatch(fetchMedicalImageAction(accessToken!, medicalImageIds[idx]))
  }, [accessToken, medicalImageIds, idx])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    if (isBoundingBoxes && medicalImage?.predictions_normalizeds) {
      setBoundingBoxPredictions(
        // @ts-ignore
        (medicalImage?.predictions_normalizeds || [])
          .filter((p) => p.bounding_box)
          .map((p) => ({
            prediction: Object.keys(p.key_values_json).map((k) => [k, p.key_values_json[k]]),
            boundingBox: parseBoundingBoxDescription(p.bounding_box!),
            id: p.id,
          }))
      )
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [medicalImage, isBoundingBoxes])

  /*
    Methods
  */

  const handleKeyDown = (e: any) => {
    const n = parseInt(e.key, 10)
    if (e.key === 'Shift' && !needsBoundingBoxPrediction) {
      setIsDrawingBoundingBox(true)
    }
    if (e.repeat || gradeType === GradeType.Binary || !DEFAULT_SCALE.includes(n)) return

    setKeyDown(n)
  }

  const handleKeyUp = (e: any) => {
    const n = parseInt(e.key, 10)

    setIsDrawingBoundingBox(false)
    if (gradeType === GradeType.Binary || !DEFAULT_SCALE.includes(n)) return

    handlePrediction(n)
    setKeyDown(undefined)
  }

  const keyValuePredictionParams = (result: [string, string[]][]): predictions_normalized_insert_input => ({
    condition_id: trainingIteration?.condition.id,
    display_name: trainingIteration?.condition.display_name,
    grade: undefined,
    issue: undefined,
    key_values_json: Object.fromEntries(result),
    medical_images_id: medicalImage?.id,
    training_iteration_id: trainingIteration?.id,
    type: 'specialist-medical_image-key_values_tagger',
    vet_id: user?.id,
  })

  const handleKeyValuePrediction = (result: [string, string[]][]) => {
    if (isBoundingBoxes) {
      setBoundingBoxPredictions([
        ...boundingBoxPredictions.slice(0, -1),
        { boundingBox: last(boundingBoxPredictions)!.boundingBox, prediction: result },
      ])
    } else {
      insertPrediction(keyValuePredictionParams(result))
    }
  }

  const insertPrediction = async (newPrediction: predictions_normalized_insert_input) => {
    setIsInsertingPrediction(true)
    if (prediction) await dispatch(deletePredictionAction(accessToken, prediction.id))
    await dispatch(insertPredictionsAction(accessToken, [newPrediction]))
    handleGoNext()
  }

  const deletePredictions = async (ids: number[]) => {
    await dispatch(deletePredictionsAction(accessToken, ids))
    dispatch(fetchMedicalImageAction(accessToken!, medicalImageIds[idx!]))
  }

  const handlePrediction = async (grade?: number, binary?: boolean, issue?: Issue) => {
    let display_name
    if (!issue) {
      display_name = `${(grade ? grade > 1 : binary) ? '' : 'NOT '}${trainingIteration?.condition.display_name}`
    }
    const data: predictions_normalized_insert_input = {
      condition_id: trainingIteration?.condition.id,
      display_name,
      grade,
      issue,
      medical_images_id: medicalImage?.id,
      training_iteration_id: trainingIteration?.id,
      type: 'specialist-medical_image-binary_tagger',
      vet_id: user?.id,
    }
    insertPrediction(data)
  }

  const saveBoundingBoxPredictions = async (none: boolean) => {
    const predictions = boundingBoxPredictions
      .filter((b) => !b.id)
      .map((b) => extend(keyValuePredictionParams(b.prediction!), { bounding_box: boundingBoxDescription(b.boundingBox) }))
    setIsInsertingPrediction(true)
    if (predictions.length) {
      await dispatch(insertPredictionsAction(accessToken, predictions))
    } else if (none) {
      await handlePrediction(undefined, false)
    }
    handleGoNext()
  }

  const handleOtherPrediction = async (condition: Conditions_conditions) => {
    const otherPrediction: predictions_normalized_insert_input = {
      condition_id: condition.id,
      display_name: condition.display_name,
      medical_images_id: medicalImage?.id,
      training_iteration_id: trainingIteration?.id,
      type: 'specialist-medical_image-binary_tagger',
      vet_id: user?.id,
    }
    setOtherConditions(uniq([...otherConditions, condition]))
    await dispatch(insertPredictionsAction(accessToken, [otherPrediction]))
    handlePrediction(undefined, false)
  }

  const handleGoNext = () => {
    if (medicalImageIds[idx! + 1]) {
      setIdx(idx! + 1)
    } else {
      setDisplayFeedbackModal(true)
    }
  }

  const handleGoBack = () => setIdx(idx! - 1)

  const isNetworking = isInsertingPrediction || isQuerying[QueryName.FetchMedicalImage]
  const unsavedBoundingBoxPredictions = isBoundingBoxes && boundingBoxPredictions.filter((b) => !b.id).length > 0
  const backEnabled = (idx || 0) > 0 && !isNetworking && !unsavedBoundingBoxPredictions
  const species = speciesFor(medicalImage?.species || medicalImage?.case?.patient.species)
  const filteredConditions = filterConditions(conditions, species).filter((c) => c.id !== trainingIteration?.condition.id)
  const options = conditionOptions(filteredConditions)

  return (
    <Layout irxMode>
      <Modal
        centered
        className="z-max-1"
        contentClassName="dark-modal"
        fade={false}
        isOpen={displayTrainingIterationPrompt}
        toggle={() => setDisplayTrainingIterationPrompt(false)}
      >
        <ModalHeader>{trainingIteration?.condition.display_name}</ModalHeader>

        <ModalBody>
          <p style={{ whiteSpace: 'pre-wrap' }}>{trainingIteration?.condition.training_iteration_prompt}</p>
        </ModalBody>

        <ModalFooter style={{ border: 'none !important' }}>
          <Button className="width-100px" color="primary" onClick={() => setDisplayTrainingIterationPrompt(false)}>
            Okay
          </Button>
        </ModalFooter>
      </Modal>

      <Feedback displayName={trainingIteration?.condition.display_name} id={trainingIteration?.id} isOpen={displayFeedbackModal} />

      {!isUndefined(idx) && medicalImageIds.length && !displayTrainingIterationPrompt ? (
        <div
          className="d-flex flex-column justify-content-between"
          style={{ height: `calc(100vh - ${CONFIG.HEADER_HEIGHT}px)`, outline: 'none' }}
        >
          <div id="binary-tagger-progress" className="z-max-1">
            <div className="flex-center">
              <div className="position-relative z-max-1">
                <img
                  className={`p-1 icon-m ${backEnabled ? 'pointer' : 'opacity-50 pe-none'}`}
                  style={{ transform: 'rotate(180deg)' }}
                  src={nextIcon}
                  onClick={handleGoBack}
                />
              </div>

              <div className="position-relative">
                <p className="text-dark-bg text-m mb-0 px-2 text-right single-line">
                  Image {idx + 1} of {medicalImageIds.length}
                </p>

                {isDebug && medicalImage && (
                  <Button
                    onClick={() => navigator.clipboard.writeText(String(medicalImage.id))}
                    color="success"
                    className="position-absolute left-0 right-0 mt-2"
                    size="sm"
                  >
                    ID {medicalImage.id}
                  </Button>
                )}
              </div>

              <div className="position-relative z-max-1">
                <img
                  className={`p-1 icon-m ${!isNetworking && !unsavedBoundingBoxPredictions ? 'pointer' : 'opacity-50 pe-none'}`}
                  src={nextIcon}
                  onClick={handleGoNext}
                />
              </div>
            </div>
          </div>

          <div style={{ height: '60vh', outline: 'none', border: 'none' }} className="vw-100 d-flex justify-content-center">
            <div ref={elementRef} style={{ objectFit: 'contain' }} className="width-fit-content flex-center h-100 position-relative">
              {presignedTaggingImageUrl && (
                <img
                  style={{ transform: `rotate(${rotate}deg)${flip ? ' scaleY(-1)' : ''}` }}
                  className={`${isNetworking ? 'opacity-50' : ''} flex-center mw-100 h-100 transition-m z-max-2 border`}
                  src={presignedTaggingImageUrl}
                />
              )}

              {isBoundingBoxes && (
                <BoundingBoxCanvas
                  isDrawing={isDrawingBoundingBox && !needsBoundingBoxPrediction}
                  setBoundingBoxPredictions={setBoundingBoxPredictions}
                  boundingBoxPredictions={boundingBoxPredictions}
                />
              )}
            </div>
          </div>

          <div style={{ top: '100px', marginLeft: '40px' }} className="text-center z-max-2 position-fixed">
            <div className="text-dark-bg flex-even text-left width-300px">
              <div className="text-m text-dark-bg">
                <p className="text-xl bold m-0" onClick={() => setDisplayTrainingIterationPrompt(true)}>
                  Label {trainingIteration?.condition.display_name.toLowerCase()}
                </p>

                {isBoundingBoxes && (
                  <Badge
                    pill
                    className={`py-1 px-2 ${isDrawingBoundingBox && !needsBoundingBoxPrediction ? 'bg--secondary7' : 'bg--gray'}`}
                  >
                    Hold SHIFT to draw a bounding box
                  </Badge>
                )}

                {prediction && (
                  <p className="mb-0 mt-2 text-m text--primary bold text-m w-100">
                    {prediction.issue
                      ? 'You labeled this an issue.'
                      : `You labeled this ${prediction.grade || (prediction.display_name?.includes('NOT') ? 'NO' : 'YES')}.`}
                  </p>
                )}
              </div>

              <div className="w-100 my-3" style={{ height: '1px', backgroundColor: '#b9b9b9' }} />

              <div className="mb-1 mt-2">
                <p className="m-0">
                  {emojiFor(species)} {capitalize(medicalImage?.case?.patient.breed || '')}
                </p>
              </div>

              {last(medicalImage?.case?.consultations)?.sending_vet_notes && trainingIteration && (
                <div className="mb-1 d-flex text-s">
                  <p className="m-0 height-80px overflow-scroll">
                    {last(medicalImage!.case!.consultations[0].sending_vet_notes!.split('(s) - '))}
                  </p>
                </div>
              )}

              <div className="w-100 my-3" style={{ height: '1px', backgroundColor: '#b9b9b9' }} />

              <div className="mb-3">
                <p className="mb-0 semibold pointer text-m mb-2">
                  Another condition (not {trainingIteration?.condition.display_name}) is present?
                </p>

                <Select
                  className="react-select-dark-container z-max-1"
                  classNamePrefix="react-select-dark"
                  controlShouldRenderValue={false}
                  placeholder="Search..."
                  options={options}
                  // @ts-ignore
                  formatGroupLabel={formatGroupLabel}
                  onChange={(option: any) => {
                    const selected = filteredConditions.find((c) => c.id === option.value)
                    if (!selected) return

                    handleOtherPrediction(selected)
                  }}
                />

                {otherConditions.length > 0 && (
                  <div className="mt-3 d-flex gap-10px flex-wrap">
                    {otherConditions.slice(0, 5).map((c, idx) => (
                      <Button onClick={() => handleOtherPrediction(c)} outline size="sm" key={idx}>
                        {c.display_name}
                      </Button>
                    ))}
                  </div>
                )}

                <p className="mb-0 semibold pointer text-m mt-3">Flag image?</p>

                <div className="d-flex flex-column z-max-1 gap-5px pt-1">
                  {[Issue.LowQuality, Issue.Positioning, Issue.WrongBodyPart, Issue.Other].map((i) => (
                    <RadioButton key={i} onClick={() => handlePrediction(undefined, undefined, i)} checked={false} label={i} />
                  ))}
                </div>
              </div>

              <div className="w-100 my-3" style={{ height: '1px', backgroundColor: '#b9b9b9' }} />

              {!isBoundingBoxes && (
                <div className="d-flex flex-column gap-10px">
                  <Button color="light" className="width-100px" outline size="sm" onClick={() => setFlip(!flip)}>
                    Flip
                  </Button>

                  <Button
                    color="light"
                    className="width-100px"
                    outline
                    size="sm"
                    onClick={() => setRotate(rotate === 270 ? 0 : rotate + 90)}
                  >
                    Rotate
                  </Button>
                </div>
              )}
            </div>

            <div className="min-width-300px flex-even" />
          </div>

          {isScale && <Scale keyDown={keyDown} handlePrediction={handlePrediction} isBinary={isBinary} isNetworking={isNetworking} />}

          {!isScale && (
            <KeyValuesForm
              boundingBoxPredictions={boundingBoxPredictions}
              condition={trainingIteration.condition.display_name}
              deletePredictions={deletePredictions}
              isBoundingBoxes={isBoundingBoxes}
              medicalImageId={medicalImage?.id}
              needsBoundingBoxPrediction={needsBoundingBoxPrediction}
              prediction={prediction}
              save={handleKeyValuePrediction}
              saveBoundingBoxPredictions={saveBoundingBoxPredictions}
              setBoundingBoxPredictions={setBoundingBoxPredictions}
              settings={trainingIteration.condition.key_values_json}
            />
          )}
        </div>
      ) : (
        <Spinner color="primary" className="center" />
      )}
    </Layout>
  )
}
