import React, { useEffect } from 'react'
import { Button } from 'reactstrap'

import { MedicalImage_medical_images_by_pk_predictions_normalizeds } from '../../hasura/graphQlQueries/types/MedicalImage'
import { BoundingBoxPrediction } from './BoundingBoxCanvas'
import { pluralize } from '../../lib/helpers'

interface Step {
  label: string
  optional?: boolean
  options: string[]
  multi?: boolean
}

interface KeyValuesSettings {
  steps: Step[]
  none: { [key: string]: string }
}

interface Props {
  boundingBoxPredictions: BoundingBoxPrediction[]
  condition: string
  deletePredictions: (ids: number[]) => void
  isBoundingBoxes: boolean
  medicalImageId?: number
  needsBoundingBoxPrediction?: boolean
  prediction?: MedicalImage_medical_images_by_pk_predictions_normalizeds
  save: (result: [string, string[]][]) => void
  saveBoundingBoxPredictions: (none: boolean) => void
  setBoundingBoxPredictions: (boundingBoxPredictions: BoundingBoxPrediction[]) => void
  settings?: KeyValuesSettings
}

export default function KeyValuesForm(props: Props) {
  const [result, setResult] = React.useState<[string, string[]][]>([])

  useEffect(() => {
    if (props.prediction?.key_values_json && !props.isBoundingBoxes) {
      setResult(Object.keys(props.prediction.key_values_json).map((k) => [k, props.prediction!.key_values_json[k]]))
    } else {
      setResult([])
    }
  }, [props.prediction, props.medicalImageId, props.isBoundingBoxes])

  if (!props.settings) return null

  const { none, steps } = props.settings

  const handleClick = (label: string, option: string, isMulti: boolean) => {
    const newResult = isMulti ? [...result] : []
    const index = newResult.findIndex((r) => r[0] === label)

    if (index === -1) {
      newResult.push([label, [option]])
    } else {
      if (newResult[index][1].includes(option)) {
        newResult[index][1] = newResult[index][1].filter((o) => o !== option)
        if (!newResult[index][1].length) newResult.splice(index, 1)
      } else {
        newResult[index][1] = newResult[index][1].concat(option)
      }
    }
    setResult(newResult)
  }

  const allSelected = (steps || []).every((step) => step.optional || result.findIndex((r) => r[0] === step.label) !== -1)
  const noneSelected = none && result.length && none[0] === result[0][0] && none[1] === result[0][1][0]
  const valid = allSelected || noneSelected || (!props.needsBoundingBoxPrediction && props.boundingBoxPredictions.length > 0)
  const hasSavedBoundingBoxPredictions = props.boundingBoxPredictions.some((b) => b.id)

  return (
    <div className="d-flex align-items-start flex-column gap-15px width-fit-content mx-auto z-max-1 pb-5 mt-4">
      {(!props.isBoundingBoxes || props.needsBoundingBoxPrediction) &&
        (steps || []).map((step) => (
          <div className="d-flex" key={step.label}>
            <div>
              <p className="text-dark-bg bold mb-1 text-xs ls-sm m-0 min-width-150px">
                <span className="text-uppercase">{step.label}</span>
                <span className="regular ml-2">{step.optional ? '(Optional)' : ''}</span>
              </p>
            </div>

            <div className="d-flex flex-wrap max-width-700px gap-10px">
              {step.options.map((option) => {
                const isSelected = result.find((r) => r[0] === step.label && r[1].includes(option))
                return (
                  <Button
                    className="min-width-100px"
                    onClick={() => handleClick(step.label, option, Boolean(step.multi))}
                    color={isSelected ? 'success' : 'secondary'}
                    outline={!isSelected}
                    key={option}
                    size="sm"
                  >
                    {option}
                  </Button>
                )
              })}
            </div>
          </div>
        ))}

      {!props.needsBoundingBoxPrediction && (
        <div>
          {props.boundingBoxPredictions
            .filter((r) => r.prediction)
            .map((r, idx) => (
              <p key={idx} className="text-dark-bg m-0">
                {idx + 1}) {r.prediction?.map((p) => p[1].join(', ')).join(' / ')}
                {!r.id && (
                  <span
                    onClick={() => props.setBoundingBoxPredictions(props.boundingBoxPredictions.filter((_, idx2) => idx !== idx2))}
                    className="underline ml-2 pointer"
                  >
                    Delete
                  </span>
                )}
              </p>
            ))}
        </div>
      )}

      <div className="d-flex gap-10px justify-content-center mt-3">
        {!props.isBoundingBoxes && (
          <Button className="min-width-150px" onClick={() => props.save(result)} disabled={!valid} color="success">
            Save
          </Button>
        )}

        {props.isBoundingBoxes ? (
          !props.needsBoundingBoxPrediction && props.boundingBoxPredictions.length === 0 ? (
            <div className="flex-center flex-column gap-10px max-width-500px">
              <p className="m-0 text-dark-bg semibold text-l text-center">
                {props.condition.includes('Foreign')
                  ? 'Draw boxes around visible foreign material or its effects (ex. distension, plication, etc.)'
                  : `Draw boxes around ${props.condition.toLowerCase()}`}
              </p>
              <p className="m-0 text-dark-bg text-l">OR</p>
              <Button className="min-width-150px" onClick={() => props.saveBoundingBoxPredictions(true)} color="danger" outline>
                Confirm no {props.condition.toLowerCase()} found
              </Button>
            </div>
          ) : (
            <Button
              className="min-width-150px"
              onClick={() => {
                if (props.needsBoundingBoxPrediction) {
                  props.save(result)
                  setResult([])
                } else {
                  props.saveBoundingBoxPredictions(false)
                }
              }}
              disabled={!valid}
              color="success"
            >
              {props.needsBoundingBoxPrediction ? 'Add label' : `Confirm ${pluralize('label', props.boundingBoxPredictions.length)}`}
            </Button>
          )
        ) : null}

        {hasSavedBoundingBoxPredictions && !props.needsBoundingBoxPrediction && (
          <Button
            color="danger"
            onClick={() => props.deletePredictions(props.boundingBoxPredictions.filter((p) => p.id).map((p) => p.id!))}
          >
            Delete labels
          </Button>
        )}

        {props.needsBoundingBoxPrediction && (
          <Button
            onClick={() => props.setBoundingBoxPredictions(props.boundingBoxPredictions.slice(0, -1))}
            color="primary"
            outline
            className="width-150px"
          >
            Redraw
          </Button>
        )}
      </div>
    </div>
  )
}
