import React from 'react'
import { navigate } from 'gatsby'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Textarea } from '../../components/common/Input'
import { UsersState, slackMessageAction, usersSelector } from '../../hasura/slices/users'
import { updateTrainingIterationFeedbackAction } from '../../hasura/slices/medical-images'
import { useDispatch, useSelector } from 'react-redux'

interface Props {
  isOpen: boolean
  id?: number
  displayName?: string
}

export default (props: Props) => {
  const [feedback, setFeedback] = React.useState<string | undefined>()

  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const submitFeedback = () => {
    if (!props.id || !feedback) return

    const msg = `${user?.display_name} submitted tagging set feedback: ${feedback} (condition id: ${props.displayName}))`
    dispatch(slackMessageAction('ai-report', msg))
    dispatch(updateTrainingIterationFeedbackAction(accessToken, props.id, feedback))
    navigate('/cases')
  }

  const skipFeedback = () => navigate('/cases')

  return (
    <Modal
      fade={false}
      autoFocus={false}
      centered
      contentClassName="dark-modal min-width-500px"
      zIndex={2147483004}
      isOpen={props.isOpen}
      toggle={skipFeedback}
      className="min-width-700px"
    >
      <ModalHeader toggle={skipFeedback}>Feedback</ModalHeader>

      <ModalBody>
        <p className="text-dark-bg">
          Is there feedback you'd like to provide on the images in this tagging set?
          <br />
          <span className="text-xs">For example: many images are positive for a condition different than the one specified.</span>
        </p>
        <Textarea
          autoFocus
          className="w-100 min-height-200px"
          dark
          onChange={(e: any) => setFeedback(e.target.value)}
          value={feedback}
        />
      </ModalBody>

      <ModalFooter style={{ border: 'none !important' }}>
        <div className="d-flex justify-content-end align-items-center w-100">
          <div>
            <Button outline className="mx-3" color="danger" onClick={skipFeedback}>
              Skip
            </Button>

            <Button disabled={!feedback} color="secondary" onClick={submitFeedback}>
              Submit
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}
