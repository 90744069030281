import React from 'react'
import range from 'lodash/range'
import { Button } from 'reactstrap'
import { Issue } from '../../lib/issue'

export const DEFAULT_SCALE = range(1, 6)

const explanationForNumber = (n: number) => {
  switch (n) {
    case 1:
      return 'Not present'
    case 2:
      return "Equivocal / can't say for sure"
    case 3:
      return 'Mild'
    case 4:
      return 'Moderate'
    case 5:
      return 'Severe'
  }
}

interface Props {
  isNetworking: boolean
  isBinary: boolean
  handlePrediction: (grade?: number, binary?: boolean, issue?: Issue) => void
  keyDown?: number
}

export default function Scale(props: Props) {
  const { isBinary, isNetworking, keyDown } = props

  return isBinary ? (
    <div className="d-flex">
      <Button
        color="dark"
        disabled={isNetworking}
        onClick={() => {
          if (!isNetworking) props.handlePrediction(undefined, false)
        }}
        className="z-max-1 flex-even text-center bold text-xl py-3 m-0 border-0"
      >
        No
      </Button>

      <Button
        color="secondary"
        disabled={isNetworking}
        onClick={() => {
          if (!isNetworking) props.handlePrediction(undefined, true)
        }}
        className="z-max-1 flex-even text-center bold text-xl py-3 m-0 border-0"
      >
        Yes
      </Button>
    </div>
  ) : (
    <div className="d-flex">
      {DEFAULT_SCALE.map((n) => (
        <Button
          color={keyDown === n ? 'success' : 'secondary'}
          disabled={isNetworking}
          onClick={() => {
            if (!isNetworking) props.handlePrediction(n)
          }}
          style={{ opacity: keyDown === n ? 1 : 1.05 - n * 0.05 }}
          className="z-max-2 flex-even text-center py-3 m-0 border-0"
        >
          <p className="bold text-l border rounded width-30px mx-auto mb-0">{n}</p>
          <p className="m-0 regular text-s single-line">{explanationForNumber(n)}</p>
        </Button>
      ))}
    </div>
  )
}
