import React, { useEffect, useRef, useState } from 'react'

interface Point {
  x: number
  y: number
}

export interface BoundingBox {
  start: Point
  end: Point
}

export interface BoundingBoxPrediction {
  id?: number
  boundingBox: BoundingBox
  prediction?: [string, string[]][]
}

export const boundingBoxDescription = (boundingBox: BoundingBox) =>
  `${boundingBox.start.x} ${boundingBox.end.x} ${boundingBox.start.y} ${boundingBox.end.y}`

export const parseBoundingBoxDescription = (description: string): BoundingBox =>
  description.split(' ').length === 4
    ? {
        start: { x: parseFloat(description.split(' ')[0]), y: parseFloat(description.split(' ')[2]) },
        end: { x: parseFloat(description.split(' ')[1]), y: parseFloat(description.split(' ')[3]) },
      }
    : { start: { x: 0, y: 0 }, end: { x: 0, y: 0 } }

interface Props {
  isDrawing: boolean
  boundingBoxPredictions: BoundingBoxPrediction[]
  setBoundingBoxPredictions: (boundingBoxPredictions: BoundingBoxPrediction[]) => void
}

export default function BoundingBoxCanvas(props: Props) {
  const [endPoint, setEndPoint] = useState<Point | null>(null)
  const [inProgressEndPoint, setInProgressEndPoint] = useState<Point | null>(null)
  const [startPoint, setStartPoint] = useState<Point | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const { width, height } = canvasRef.current ? canvasRef.current.getBoundingClientRect() : { width: null, height: null }

  useEffect(() => {
    if (!startPoint || !endPoint || !width || !height) return

    if (Math.abs(startPoint.x - endPoint.x) < 10 || Math.abs(startPoint.y - endPoint.y) < 10) return

    const boundingBox = {
      start: { x: startPoint.x / width, y: startPoint.y / height },
      end: { x: endPoint.x / width, y: endPoint.y / height },
    }

    props.setBoundingBoxPredictions([...props.boundingBoxPredictions, { boundingBox, prediction: undefined }])
    setStartPoint(null)
    setEndPoint(null)
    setInProgressEndPoint(null)
  }, [startPoint, endPoint, width, height])

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!props.isDrawing) return

    e.stopPropagation()
    const rect = canvasRef.current?.getBoundingClientRect()
    if (!rect) return

    setStartPoint({ x: e.clientX - rect.left, y: e.clientY - rect.top })
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!props.isDrawing || !startPoint) return

    e.stopPropagation()
    const rect = canvasRef.current?.getBoundingClientRect()
    if (!rect) return

    setInProgressEndPoint({ x: e.clientX - rect.left, y: e.clientY - rect.top })
  }

  const handleMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!props.isDrawing || !startPoint) {
      setStartPoint(null)
      setInProgressEndPoint(null)
      return
    }

    e.stopPropagation()
    const rect = canvasRef.current?.getBoundingClientRect()
    if (!rect) return

    setEndPoint({ x: e.clientX - rect.left, y: e.clientY - rect.top })
  }

  const drawBoundingBox = () => {
    if (!startPoint || !inProgressEndPoint || !width || !height) return null

    const top = Math.min(startPoint.y, inProgressEndPoint.y)
    const left = Math.min(startPoint.x, inProgressEndPoint.x)
    const boxWidth = Math.abs(startPoint.x - inProgressEndPoint.x)
    const boxHeight = Math.abs(startPoint.y - inProgressEndPoint.y)

    return (
      <div
        className="position-absolute border border--secondary7 border-width-2px z-max-1"
        style={{ top, left, width: `${boxWidth}px`, height: `${boxHeight}px`, borderStyle: 'dotted !important' }}
      />
    )
  }

  return (
    <div>
      {height &&
        width &&
        props.boundingBoxPredictions.map((r, idx) => (
          <div
            key={idx}
            style={{
              top: Math.min(r.boundingBox.start.y, r.boundingBox.end.y) * height,
              left: Math.min(r.boundingBox.start.x, r.boundingBox.end.x) * width,
              width: `${width * Math.abs(r.boundingBox.start.x - r.boundingBox.end.x)}px`,
              height: `${height * Math.abs(r.boundingBox.start.y - r.boundingBox.end.y)}px`,
            }}
            className="position-absolute border border--secondary7 border-width-2px z-max-1"
          >
            {r.prediction && (
              <div
                style={{ top: 'calc(100% + 5px)' }}
                className="z-max-1 position-absolute text--white text-s semibold min-width-200px"
              >
                {idx + 1}) {r.prediction?.map((p) => p[1].join(', ')).join(' / ')}
              </div>
            )}
          </div>
        ))}

      {drawBoundingBox()}

      <canvas
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        className={`
          ${props.isDrawing ? 'border border--secondary7 border-width-4px' : ''} 
          h-100 w-100 position-absolute top-0 left-0 z-max-1 border border-primary
        `}
        ref={canvasRef}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  )
}
