import React from 'react'

import { GroupedOption } from '../../lib/helpers'

export default (data: GroupedOption) => (
  <div className="d-flex align-items-center justify-content-between">
    <p className="m-0 text-xs">{data.label}</p>

    <p
      style={{ width: '20px', height: '20px', lineHeight: '20px' }}
      className="bg-dark text-dark-bg rounded-circle p-1 m-0 text-center d-flex align-items-center justify-content-center text-xxs"
    >
      {data.options.length}
    </p>
  </div>
)
